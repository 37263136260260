import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import TelegramIcon from "../assets/images/telegramIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import { useSelector } from "react-redux";
import MenuIcon from "../assets/images/menu-icon.png";
import ApkDownload from "../assets/images/icons/ApkDownload.webp";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import ThemeToggle from "../views/components/ThemeToggle";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import Slider from "react-slick";

const headerPages = [
  "home",
  "sports",
  "betby",
  "public-sports",
  "live-dealer",
  "evolution",
  "aviator",
  "sign-in",
  "/",
];

const Header = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  useEffect(() => {
    const matchedValue = headerPages.find((f) => {
      if (location?.pathname == "/public-sports") {
        return f === location?.pathname.split("/")?.[1];
      } else {
        return location?.pathname.includes(f);
      }
    });
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 5000,
  };

  return (
    <>
      {/* {(websiteNotifications.length && showNotifications && (
        <div className="announcement">
          <div className="title">Trending Now</div>
          <Slider {...settings}>
            {websiteNotifications.map((notf, idx) => (
              <div
                className="info"
                key={idx}
                dangerouslySetInnerHTML={{ __html: notf?.content }}
              ></div>
            ))}
          </Slider>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null} */}

      <header className="header header_bfrlgn">
        <div className="topHead">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <Button
                    variant="primary menu-btn"
                    onClick={() => setShowLeftBar((p) => !p)}
                    className="d-block d-md-none"
                  >
                    <img src={MenuIcon} alt="User Menu Icon" width={25} />
                  </Button>

                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img src={logo} alt="Logo" /> */}
                    </a>
                  </div>

                  <div className="header-left mx-lg-auto d-none d-xl-block">
                    <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                      <Nav className="me-auto">
                        <Nav.Link
                          href="/sports"
                          className={
                            activePage == "sports" || activePage === "/"
                              ? "active"
                              : ""
                          }
                        >
                          Home
                        </Nav.Link>
                        <Nav.Link
                          href="/public-sports"
                          className={
                            activePage == "public-sports" ? "active" : ""
                          }
                        >
                          Exchange
                        </Nav.Link>
                        {/* <Nav.Link
                        href="/betby"
                        className={activePage == "betby" ? "active" : ""}
                      >
                        Sportsbook
                      </Nav.Link> */}
                        <Nav.Link
                          href="/live-dealer-public"
                          className={
                            activePage == "live-dealer" ? "active" : ""
                          }
                        >
                          Live Dealers
                        </Nav.Link>
                        <Nav.Link
                          href="/sign-in"
                          className={activePage == "sign-in" ? "" : ""}
                        >
                          Vivo
                        </Nav.Link>
                        <Nav.Link
                          href="/casino/vivo"
                          className={activePage == "sign-in" ? "" : ""}
                        >
                          Aviator
                        </Nav.Link>
                        <Nav.Link
                          href="/atlas-lobby"
                          className={activePage == "sign-in" ? "" : ""}
                        >
                          Sportsbook2
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </div>

                  <div className="headerRight ms-auto ms-xl-0">
                    <div className="mx-3">
                      <GamesSearchModal />
                    </div>
                    <ThemeToggle />

                    <a
                      href="https://dl.dropbox.com/scl/fi/ijir5iy862ma8s3x4079y/Goa777.apk?rlkey=5baa2yvdgdqynjgujrdu9sgnb&st=x3t34cxb&dl=0"
                      download="https://dl.dropbox.com/scl/fi/ijir5iy862ma8s3x4079y/Goa777.apk?rlkey=5baa2yvdgdqynjgujrdu9sgnb&st=x3t34cxb&dl=0"
                      className="me-2 d-flex d-md-none ApkDownload"
                    >
                      <img src={ApkDownload} alt="apk download" width={38} />
                    </a>

                    <Button
                      variant="primary"
                      onClick={() => navigate("/sign-in")}
                      className="login_btn me-2"
                    >
                      Login
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => navigate("/mobile-number")}
                      className="signup_btn"
                    >
                      Join Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showLeftBar && <MobLeftbar className="d-block d-md-none" />}
      </header>
    </>
  );
};

export default Header;
