import React, { Suspense, useEffect } from "react";
import "./App.scss";
import HeaderAfterLogin from "./containers/HeaderAfterLogin";
import Footer from "./containers/Footer";
import BottomNav from "./containers/BottomNav";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";

// import Termsandcondition from "./containers/Termsandcondition";
import { useLocation } from "react-router-dom";
import { WEBSITE_THEME } from "./config/const";
import LoadingImg from "./assets/images/loader.gif";
import AtlasLobby from "./views/pages/privatePages/sportsBook/atlasLobby";
import { APP_CONST } from "./config/const";

const ProfitAndLoss = React.lazy(() =>
  import("./views/pages/privatePages/profit&loss/ProfitAndLoss")
);
const ContactUsBefore = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/ContactUsBefore")
);
const Providers = React.lazy(() =>
  import("./views/pages/privatePages/providers/Providers")
);
const Rummy = React.lazy(() =>
  import("./views/pages/privatePages/rummy/Rummy")
);
// const LoadingImg = React.lazy(() => import("./assets/images/loader.gif"));
const FirstGamePage = React.lazy(() =>
  import("./views/pages/privatePages/gamesPage/FirstGamePage")
);
const SecondGamePage = React.lazy(() =>
  import("./views/pages/privatePages/gamesPage/SecondGamePage")
);
const ThirdGamePage = React.lazy(() =>
  import("./views/pages/privatePages/gamesPage/ThirdGamePage")
);
const FourthGamePage = React.lazy(() =>
  import("./views/pages/privatePages/gamesPage/FourthGamePage")
);
const AllGamePage = React.lazy(() =>
  import("./views/pages/privatePages/gamesPage/AllGamePage")
);
const Slots = React.lazy(() => import("./views/pages/privatePages/slots"));
const Spribe = React.lazy(() => import("./views/pages/privatePages/spribe"));
const SupportCustomer = React.lazy(() =>
  import("./views/pages/privatePages/support-customer/SupportCustomer")
);
const LiveDealer = React.lazy(() =>
  import("./views/pages/privatePages/gamesPage/LiveDealer")
);
const GatewaysList = React.lazy(() =>
  import("./views/pages/privatePages/deposit/gatewaysList")
);
const DepositGateway = React.lazy(() =>
  import("./views/pages/privatePages/deposit/depositGateway")
);
const Bonus = React.lazy(() => import("./views/pages/privatePages/bonus"));
const BonusCard = React.lazy(() =>
  import("./views/pages/privatePages/bonus/bonusCard")
);
const Referral = React.lazy(() =>
  import("./views/pages/privatePages/Referral")
);
const PublicSports = React.lazy(() =>
  import("./views/pages/publicPages/publicSports")
);
const LiveDealerPublic = React.lazy(() =>
  import("./views/pages/publicPages/LiveDealerPublic")
);
const AuraGames = React.lazy(() =>
  import("./views/pages/privatePages/AuraGames")
);
const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const Support = React.lazy(() => import("./views/pages/privatePages/support"));
const Contact = React.lazy(() => import("./views/pages/privatePages/contact"));

const ResponsibleGaming = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/ResponsibleGaming")
);

const PrivacyPolicy = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/PrivacyPolicy")
);

const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const Fairplay = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Fairplay")
);
const GameRules = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/GameRules")
);

const Termsandcondition = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Termsandcondition")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);
const Promotion = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Promotion")
);

const Kingmaker = React.lazy(() =>
  import("./views/pages/privatePages/Kingmaker")
);

const Maintenance = React.lazy(() =>
  import("./views/pages/publicPages/Maintenance")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const location = useLocation();
  const { appDetails, appTheme, organisationStatus } = useSelector(
    (state) => state.app
  );

  //const maxToast = 1;
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.pathname !== "/" && location?.pathname !== "/sports") {
      if (window?.tidioChatApi) {
        window?.tidioChatApi?.hide();
      }
    } else {
      if (window?.tidioChatApi) {
        window?.tidioChatApi?.show();
      }
    }
  }, [location]);

  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
    if (
      organisationStatus !== null &&
      organisationStatus !== APP_CONST.ORGANISATION_STATUS.Live &&
      window.location.pathname.indexOf("maintenance") < 0
    ) {
      window.location.href = "/maintenance";
    }
  }, [appDetails]);

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    if (appTheme === WEBSITE_THEME.LIGHT) {
      document.body.classList.add("light-theme");
    } else {
      document.body.classList.remove("light-theme");
    }

    dispatch(getAppDetails());

    setVhUnit();
    window.addEventListener("resize", setVhUnit);
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <div className="loading_img">
            <img src={LoadingImg} alt="loading" width={200} />
          </div>
        }
      >
        <Routes>
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <Footer />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/kingmaker"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Kingmaker />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Spribe"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Spribe />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/auragames"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <AuraGames />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route path="/sports" element={<Sports isAuth={isAuth} />} />
          <Route path="/atlas-lobby" element={<AtlasLobby isAuth={isAuth} />} />
          <Route
            // path="/sports/exchange"
            path="/sportsbook/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <HeaderAfterLogin />
                <ExchangeLobbyPage />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="/betby" element={<Betby isAuth={isAuth} />} />
          <Route
            path="/support"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Support />

                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Contact />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/ResponsibleGaming"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGaming />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Fairplay />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/gamerules"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GameRules />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <PrivacyPolicy />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/termsandcondition"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Termsandcondition />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/contacts"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ContactUsBefore />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/ResponsibleGamings"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResponsibleGaming />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/termsandconditions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Termsandcondition />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/gameruless"
            element={
              <PublicRoutes isAuth={isAuth}>
                <GameRules />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Fairplay />
                <BottomNav />
              </PublicRoutes>
            }
          />

          <Route
            path="/promotions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Promotion />
                <BottomNav />
              </PublicRoutes>
            }
          />
          <Route
            path="/promotion"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Promotion />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/profit&loss"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ProfitAndLoss />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/support-customer"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SupportCustomer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/show-all-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Providers />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/first-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FirstGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/second-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SecondGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/third-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ThirdGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fourth-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FourthGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/all-game"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <AllGamePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/live-rummy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Rummy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/live-dealer"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <LiveDealer />
              </ProtectedRoutes>
            }
          />
          <Route path="/public-inplay" element={<PublicSports />} />
          <Route path="/public-sports" element={<PublicSports />} />
          <Route
            path="/"
            element={
              <PublicRoutes>
                <Sports isAuth={isAuth} />
                {/* <BottomNav /> */}
              </PublicRoutes>
            }
          />
          <Route
            path="/live-dealer-public"
            element={
              <PublicRoutes>
                <LiveDealerPublic />
              </PublicRoutes>
            }
          />
          <Route
            path="/maintenance"
            element={<Maintenance organisationStatus={organisationStatus} />}
          />
        </Routes>
      </Suspense>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
        // limit={4}
      />
    </>
  );
};

export default App;
