import VivoTeenPatti from "../../../src/assets/images/casino/VivoTeenPatti.webp";
import VivoAndarBahar from "../../../src/assets/images/casino/VivoAndarBahar.webp";
import game1 from "../../../src/assets/images/casino/TeenPatti.jpg";
import game2 from "../../../src/assets/images/casino/TeenPatti20-20.jpg";
import game3 from "../../../src/assets/images/casino/AndarBahar.jpg";
import game4 from "../../../src/assets/images/casino/32Cards.jpg";
import game5 from "../../../src/assets/images/casino/Roulette.jpg";
import game7 from "../../../src/assets/images/casino/Cricketwar.jpg";
import game8 from "../../../src/assets/images/casino/Sicbo.jpg";
import game9 from "../../../src/assets/images/casino/Baccarat.jpg";
import game10 from "../../../src/assets/images/casino/WorliMatka.jpg";
import game11 from "../../../src/assets/images/casino/DragonTiger.jpg";
import game12 from "../../../src/assets/images/casino/OneDayTeenPatti.jpg";
import game13 from "../../../src/assets/images/casino/KingRace.jpg";
import game14 from "../../../src/assets/images/casino/CAsinoQueen.jpg";
import game15 from "../../../src/assets/images/casino/ClassicAndarBahar.jpg";
import game16 from "../../../src/assets/images/casino/Goa'sAndarBahar.jpg";
import game17 from "../../../src/assets/images/casino/Lucky7.jpg";
import game18 from "../../../src/assets/images/casino/Poker.jpg";
import game20 from "../../../src/assets/images/casino/RNG3CardsJudgement.jpg";
import game21 from "../../../src/assets/images/casino/RNG3-PlayerTeenPatti.jpg";
import game22 from "../../../src/assets/images/casino/RNG32Cards.jpg";
import game23 from "../../../src/assets/images/casino/RNGAkbarRomeoWalter.jpg";
import game24 from "../../../src/assets/images/casino/RNGAndarBahar2020.jpg";
import game25 from "../../../src/assets/images/casino/RNGBaccarat.jpg";
import game26 from "../../../src/assets/images/casino/RNG-Baccarat-2020.jpg";
import game27 from "../../../src/assets/images/casino/RNGCasinoQueen.jpg";
import game28 from "../../../src/assets/images/casino/RNGCasinoQueen2020.jpg";
import game29 from "../../../src/assets/images/casino/RNgCricket2020.jpg";
import game30 from "../../../src/assets/images/casino/RNGDragonTiger.jpg";
import game31 from "../../../src/assets/images/casino/RNGDragonTiger2020.jpg";
import game32 from "../../../src/assets/images/casino/RNGJoker.jpg";
import game33 from "../../../src/assets/images/casino/RNGKabutarFuddi.jpg";
import game34 from "../../../src/assets/images/casino/RNGPoker.jpg";
import game35 from "../../../src/assets/images/casino/RNGPoker2020.jpg";
import game36 from "../../../src/assets/images/casino/RNGTeenPatti.jpg";
import game37 from "../../../src/assets/images/casino/RNGTeenPatti2020.jpg";
import game38 from "../../../src/assets/images/casino/TeenPattiFaceOff.jpg";
// import game366 from "../../../src/assets";

import populargame1 from "../../../src/assets/images/casino/popular/vivo-andar-bahar.webp";
import populargame2 from "../../../src/assets/images/casino/popular/vivo-teen-patti.jpg";
import populargame3 from "../../../src/assets/images/casino/popular/vivo-oracle-blaze-roulette.webp";
import populargame4 from "../../../src/assets/images/casino/popular/vivo-portomaso-roulette.jpg";
import populargame5 from "../../../src/assets/images/casino/popular/vivo-las-vegas-roulette.webp";
import populargame6 from "../../../src/assets/images/casino/popular/vivo-macau-baccarat.webp";
import populargame8 from "../../../src/assets/images/casino/popular/vivo-las-vegas-blackjack.webp";
import populargame9 from "../../../src/assets/images/casino/popular/vivo-dragon-tiger.webp";
import populargame10 from "../../../src/assets/images/casino/popular/GonzosTreasureHunt.webp";
import populargame11 from "../../../src/assets/images/casino/popular/Auto-Roulette.webp";
import populargame12 from "../../../src/assets/images/casino/popular/BetOnTeenPatti.webp";
import populargame13 from "../../../src/assets/images/casino/popular/AndarBahar.webp";
import populargame14 from "../../../src/assets/images/casino/popular/Baccarat.jpg";
import populargame15 from "../../../src/assets/images/casino/popular/lucky7.jpg";
import populargame16 from "../../../src/assets/images/casino/popular/DragonTiger.webp";
import populargame17 from "../../../src/assets/images/casino/popular/OttAndarBahar.jpg";
import populargame18 from "../../../src/assets/images/casino/popular/ItalianBlackjack.jpg";
import populargame19 from "../../../src/assets/images/casino/popular/DiamondRoulette.jpg";
import aviator from "../../assets/images/sidebar-games/aviator.jpg";

export const casinoTabs = {
  roulettetab: {
    RouletteVip: {
      href: "/casino/evolution/RouletteVip",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino02.png", alt: "" },
    },

    RouletteLive: {
      href: "/casino/evolution/RouletteLive",
      code: "1000102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino03.png", alt: "" },
    },
    AmericanRoulette: {
      href: "/casino/evolution/AmericanRoulette",
      code: "1000011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino04.png", alt: "" },
    },
    AmericanRouletteFirstPerson: {
      href: "/casino/evolution/AmericanRouletteFirstPerson",
      code: "1000312",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino08.png", alt: "" },
    },
    LightningRouletteFirstPerson: {
      href: "/casino/evolution/LightningRouletteFirstPerson",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino05.png", alt: "" },
    },
    LightingsRoulette: {
      href: "/casino/evolution/LightingRoulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino015.png", alt: "" },
    },
    AutoRouletteLive: {
      href: "/casino/evolution/doubleballroulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino014.png", alt: "" },
    },
    LightingRoulette: {
      href: "/casino/evolution/LightingRoulette",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/casino01111.png",
        alt: "",
      },
    },
    namasteroulette: {
      href: "/casino/ezugi/namasteroulette",
      code: 221005,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casinio28.png", alt: "" },
    },

    AutoRouletteLive: {
      href: "/casino/evolution/DoubleBallRouletteLive",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino070.PNG", alt: "" },
    },

    PortmasoRoulette: {
      href: "/casino/evolution/PortmasoRoulette",
      code: 611004,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino013.png", alt: "" },
    },
    marinaroulette: {
      href: "/casino/ezugi/marinaroulette",
      code: 321000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino019.png", alt: "" },
    },

    OracleRoulette360: {
      href: "/casino/ezugi/OracleRoulette360",
      code: "611003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino030.png", alt: "" },
    },
    diamondroulette: {
      href: "/casino/ezugi/diamondroulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino028.png", alt: "" },
    },
    speedroulette: {
      href: "/casino/ezugi/speedroulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino029.PNG", alt: "" },
    },

    oracleRoulette: {
      href: "/casino/ezugi/oracleRoulette",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino010.png", alt: "" },
    },
    AutoRoulette1: {
      href: "/casino/ezugi/AutoRoulette1",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino026.png", alt: "" },
    },
    cumbiaRoulette: {
      href: "/casino/ezugi/cumbiaRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/casino031.png",
        alt: "",
        className: "size_images",
      },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino014.png", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino012.png", alt: "" },
    },

    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino011.png", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/ezugi/autoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino032.PNG", alt: "" },
    },

    TurkishsRoulettes: {
      href: "/casino/evolution/TurkishsRoulettes",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino017.png", alt: "" },
    },

    Roulettelive: {
      href: "/casino/evolution/Roulettelive",
      code: "1",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino033.png", alt: "" },
    },
    autORoulette: {
      href: "/casino/ezugi/autORoulette",
      code: "1",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino035.png", alt: "" },
    },
    Dragonroulette: {
      href: "/casino/xpg/Dragonroulette",
      code: "",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino023.png", alt: "" },
    },
    LiveSpeedRoulette: {
      href: "/casino/ezugi/LiveSpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino032.PNG", alt: "" },
    },

    LightingLiveRoulette: {
      href: "/casino/evolution/LightingLiveRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino022.png", alt: "" },
    },
    Roulette: {
      href: "/casino/evolution/Roulette",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino078.PNG", alt: "" },
    },

    americanRouletLive: {
      href: "/casino/evolution/americanRouletLive",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino076.PNG", alt: "" },
    },
    autoRouletteLive: {
      href: "/casino/evolution/autoRouletteLive",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino077.PNG", alt: "" },
    },
    AutoFastRouletLive: {
      href: "/casino/evolution/AutoFastRouletLive",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino075.PNG", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/evolution/Baccaratfirstperson",
      code: "1000145",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino027.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino028.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino029.png", alt: "" },
    },
    baccaratLive: {
      href: "/casino/evolution/baccaratLive",
      code: "1000012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino0011.PNG", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },

    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },

    KnockOutbaccarat: {
      href: "/casino/ezugi/KnockOutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino029.png", alt: "" },
    },
    noCommisionBaccarat: {
      href: "/casino/ezugi/noCommisionBaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino027.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/evolution/Baccarat",
      code: "BAC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino080.PNG", alt: "" },
    },
    Baccaratpro: {
      href: "/casino/evolution/Baccaratpro",
      code: "1000145",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino040.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/evolution/Nocommissiobaccarat",
      code: "10",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino041.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/evolution/Knockoutbaccarat",
      code: "",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino042.PNG", alt: "" },
    },
    baccaratlive: {
      href: "/casino/ezugi/baccaratlive",
      code: "10",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino028.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },
    // Baccaratpro02: {
    //   href: "/casino/ezugi/Baccaratpro02",
    //   code: "101",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/casino028.png", alt: "" },
    // },
    // SpeedBaccarat: {
    //   href: "/casino/ezugi/SpeedBaccarat",
    //   code: "105",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/casino029.png", alt: "" },
    // },
    // Marina03baccarat: {
    //   href: "/casino/ezugi/Marina03baccarat",
    //   code: "32103",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    // },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino032.png", alt: "" },
    },
    AndarBaharlive: {
      href: "/casino/ezugi/AndarBaharlive",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino030.png", alt: "" },
    },
    AndarBahar: {
      href: "/casino/ezugi/AndarBahar",
      code: "ABC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino091.PNG", alt: "" },
    },
    GoaAndarBahar: {
      href: "/casino/ezugi/GoaAndarBahar",
      code: "AB2",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino090.PNG", alt: "" },
    },
    Andarbahar: {
      href: "/casino/evolution/Andarbahar",
      code: "",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0112.png", alt: "" },
    },
    Andarbaharlive: {
      href: "/casino/evolution/Andarbaharlive",
      code: "",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0113.png", alt: "" },
    },
    AndarbaharLive: {
      href: "/casino/xpg/AndarbaharLive",
      code: "328000",
      casino: "wco",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0115.png", alt: "" },
    },
    AndarBaharlivee: {
      href: "/casino/xpg/AndarBaharlivee",
      code: "328000",
      casino: "wco",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0114.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino033.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino061.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino032.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/xpg/TeenPatti03",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino034.png", alt: "" },
    },
    TeenPatti04: {
      href: "/casino/xpg/TeenPatti04",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino031.png", alt: "" },
    },
    TeenPatti05: {
      href: "/casino/xpg/TeenPatti05",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino035.png", alt: "" },
    },
    TeenPatti06: {
      href: "/casino/xpg/TeenPatti06",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino032.png", alt: "" },
    },
    Poker: {
      href: "/casino/ezugi/Poker",
      code: "PK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/casino093.PNG", alt: "" },
    },
    ThreeTeenPatti: {
      href: "/casino/ezugi/ThreeTeenPatti",
      code: "V3TP",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/casino094.PNG", alt: "" },
    },
    TeenPatti09: {
      href: "/casino/ezugi/TeenPatti09",
      code: "TP",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/casino092.PNG", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/evolution/Blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino060.png", alt: "" },
    },

    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino040.png", alt: "" },
    },
    vipBlackjack: {
      href: "/casino/ezugi/vipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino039.png", alt: "" },
    },
    italianBlackjack: {
      href: "/casino/ezugi/italianBlackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino053.png", alt: "" },
    },
    unlimitedblackjack: {
      href: "/casino/ezugi/unlimitedblackjack",
      code: "51",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino043.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino044.png", alt: "" },
    },
    rumbablackjack: {
      href: "/casino/ezugi/rumbablackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino050.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino046.png", alt: "" },
    },
    goldBlackjack: {
      href: "/casino/xpg/goldBlackjack",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino042.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/Blackjacklive",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino099.PNG", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/evolution/Blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino060.png", alt: "" },
    },
    Blackjacklivee: {
      href: "/casino/evolution/Blackjacklivee",
      code: "1000032",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0222.png", alt: "" },
    },
    BlackJacklivee: {
      href: "/casino/evolution/BlackJacklivee",
      code: "1000031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0223.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/supernowa/BlackjackLive",
      code: "422",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino099.PNG", alt: "" },
    },
    GoldBlackjackLive: {
      href: "/casino/ezugi/GoldBlackjackLive",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino042.png", alt: "" },
    },
    PlaastinumBlackjacksss: {
      href: "/casino/ezugi/PlaastinumBlackjacksss",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0224.png", alt: "" },
    },
    ItasasdlianBlackjackaasas: {
      href: "/casino/ezugi/ItasasdlianBlackjackaasas",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0225.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/casino057.png", alt: "" },
    },
    thirtytwoCardss: {
      href: "/casino/ezugi/thirtytwoCardss",
      code: "C32",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/card/casino0100.PNG", alt: "" },
    },
  },
  sevenLuckytab: {
    luckyseven: {
      href: "/casino/ezugi/luckyseven",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/casino056.png", alt: "" },
    },
    Lucky: {
      href: "/casino/supernowa/Lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/lucky7/casino100.PNG", alt: "" },
    },
  },
  sicbotab: {
    supersicbo: {
      href: "/casino/evolution/supersicbo",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino060.png", alt: "" },
    },
    craps: {
      href: "/casino/evolution/craps",
      code: "1000248",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino0132.png", alt: "" },
    },
    lightningdice: {
      href: "/casino/evolution/lightningdice",
      code: "1000076",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino062.png", alt: "" },
    },
    ultimatesicbo: {
      href: "/casino/ezugi/ultimatesicbo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino058.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/xpg/sicbodice04",
      code: "38",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/dice/casino059.png", alt: "" },
    },
    sicbo: {
      href: "/casino/supernowa/sicbo",
      code: "38",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/dice/casino101.PNG", alt: "" },
    },
  },
  BetOnNumberTab: {
    BetOnNumberHd: {
      href: "/casino/ezugi/BetOnNumberHd",
      code: "602000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino035.png", alt: "" },
    },
    CrazyTime: {
      href: "/casino/evolution/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino039.png", alt: "" },
    },
    DreamCatcher: {
      href: "/casino/evolution/DreamCatcher",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino0227.png", alt: "" },
    },
    MonopolyLive: {
      href: "/casino/evolution/MonopolyLive",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino0229.png", alt: "" },
    },
    WheelOfFortune: {
      href: "/casino/xpg/WheelOfFortune",
      code: "224100",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/games/casino0228.png", alt: "" },
    },
  },
  indianGames: {
    IndianNamasteRoulette: {
      href: "/casino/ezugi/IndianNamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/01.jpg", alt: "" },
    },
    IndianTeenPatti: {
      href: "/casino/ezugi/IndianTeenPatti",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/02.jpg", alt: "" },
    },

    onedayteenpatti: {
      href: "/casino/ezugi/onedayteenpatti",
      code: "227102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/03.jpg", alt: "" },
    },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/04.jpg", alt: "" },
    },
    beton: {
      href: "/casino/ezugi/beton",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/05.jpg", alt: "" },
    },
    oneday: {
      href: "/casino/ezugi/oneday",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/06.jpg", alt: "" },
    },
  },
  spribeGames: {
    Mine: {
      href: "/casino/ezugi/Mine",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/02.jpg", alt: "" },
    },
    Goal: {
      href: "/casino/ezugi/Goal",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/06.png", alt: "" },
    },

    Dice: {
      href: "/casino/ezugi/Dice",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/07.jpg", alt: "" },
    },
    Avitar: {
      href: "/casino/ezugi/Avitar",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/03.png", alt: "" },
    },
    Pilnko: {
      href: "/casino/ezugi/Pilnko",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/08.jpg", alt: "" },
    },
    Miniroulette: {
      href: "/casino/ezugi/Miniroulette",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/04.png", alt: "" },
    },
    Hilo: {
      href: "/casino/ezugi/Hilo",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/05.png", alt: "" },
    },
  },
  popularGames: {
    lucky7: {
      href: "/casino/ezugi/lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/01.jpg", alt: "" },
    },
    Monopoly: {
      href: "/casino/evolution/Monopoly",
      code: "228001",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/popular/02.jpg", alt: "" },
    },

    Dragontiger: {
      href: "/casino/ezugi/Dragontiger",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/03.jpg", alt: "" },
    },
    Speed: {
      href: "/casino/ezugi/Speed",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/04.jpg", alt: "" },
    },
    teenpatti3card: {
      href: "/casino/ezugi/teenpatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/05.jpg", alt: "" },
    },
    // Miniroulette2: {
    //   href: "/casino/ezugi/Miniroulette2",
    //   code: "228001",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/06.jpg", alt: "" },
    // },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/04.jpg", alt: "" },
    },
  },
  slotsgames: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/01.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/02.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/03.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/04.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/05.jpg", alt: "" },
    },
  },
  Proivdergames: {
    Evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/01.png", alt: "" },
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/02.png", alt: "" },
    },

    supernova: {
      href: "/casino/supernowa",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/03.png", alt: "" },
    },
    xpg: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/04.png", alt: "" },
    },
  },
  PaymentMethod: {
    netbanking: {
      href: "/casino/Evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/netbanking.svg", alt: "" },
    },
    upi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/upi.svg", alt: "" },
    },

    visa: {
      href: "/casino/supernova",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/visa.svg", alt: "" },
    },
    mastercard: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/mastercard_white.svg", alt: "" },
    },
    paytm: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/paytm.svg", alt: "" },
    },
    Phonepe: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/phonepe.svg", alt: "" },
    },
    gpay: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/gpay.svg", alt: "" },
    },
    airtel: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/airtel.svg", alt: "" },
    },
  },

  spribeGames: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/02.jpg", alt: "" },
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/06.png", alt: "" },
    },

    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/07.jpg", alt: "" },
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/03.png", alt: "" },
    },
    pilnko: {
      href: "/casino/spribe/pilnko",
      code: "pilnko",
      casino: "spribe",
      provider: "pilnko",
      homeUrl: "",
      cover: { src: "./images/spribe/08.jpg", alt: "" },
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "miniroulette",
      casino: "spribe",
      provider: "miniroulette",
      homeUrl: "",
      cover: { src: "./images/spribe/04.png", alt: "" },
    },
    hilo: {
      href: "/casino/spribe/hi-lo",
      code: "hilo",
      casino: "spribe",
      provider: "hilo",
      homeUrl: "",
      cover: { src: "./images/spribe/05.png", alt: "" },
    },
  },
};

export const allGames = [
  {
    id: 1,
    href: "/casino/vivo-teen-patti",
    img: VivoTeenPatti,
    name: "vivo teen patti",
  },
  {
    id: 2,
    href: "/casino/vivo-andar-bahar",
    img: VivoAndarBahar,
    name: "vivo andar bahar",
  },
  {
    id: 3,
    href: "/casino/ezg-teen-patti-3-card",
    img: game1,
    name: "ezg teen patti 3 card",
  },
  {
    id: 4,
    href: "/casino/sn-teen-patti-2020",
    img: game2,
    name: "sn teen patti 2020",
  },
  {
    id: 5,
    href: "/casino/ezg-andar-bahar",
    img: game3,
    name: "ezg andar bahar",
  },
  {
    id: 6,
    href: "/casino/ezg-32-cards",
    img: game4,
    name: "ezg 32 cards",
  },
  {
    id: 7,
    href: "/casino/ezg-speed-roulette",
    img: game5,
    name: "ezg speed roulette",
  },
  {
    id: 8,
    href: "/casino/ezg-cricket-war",
    img: game7,
    name: "ezg cricket war",
  },
  {
    id: 9,
    href: "/casino/ezg-sic-bo",
    img: game8,
    name: "ezg sic bo",
  },
  {
    id: 10,
    href: "/casino/ezg-baccarat",
    img: game9,
    name: "ezg baccarat",
  },
  {
    id: 11,
    href: "/casino/ezg-dragon-tiger",
    img: game11,
    name: "ezg dragon tiger",
  },
  {
    id: 12,
    href: "/casino/ezg-one-day-teen-patti",
    img: game12,
    name: "ezg one day teen patti",
  },
  {
    id: 13,
    href: "/casino/sn-rng-casino-queen",
    img: game14,
    name: "sn rng casino queen",
  },
  {
    id: 14,
    href: "/casino/sn-goas-andar-bahar",
    img: game16,
    name: "sn goas andar bahar",
  },
  {
    id: 15,
    href: "/casino/ezg-lucky-7",
    img: game17,
    name: "ezg lucky 7",
  },
  {
    id: 16,
    href: "/casino/sn-rng-3-player-teen-patti",
    img: game21,
    name: "sn rng 3 player teen patti",
  },
  {
    id: 17,
    href: "/casino/sn-rng-32-cards",
    img: game22,
    name: "sn rng 32 cards",
  },
  {
    id: 18,
    href: "/casino/sn-rng-akbar-romeo-walter",
    img: game23,
    name: "sn rng akbar romeo walter",
  },
  {
    id: 19,
    href: "/casino/sn-rng-baccarat",
    img: game25,
    name: "sn rng baccarat",
  },
  {
    id: 20,
    href: "/casino/sn-rng-casino-queen",
    img: game27,
    name: "sn rng casino queen",
  },
  {
    id: 21,
    href: "/casino/sn-rng-casino-queen-2020",
    img: game28,
    name: "sn rng casino queen 2020",
  },
  {
    id: 22,
    href: "/casino/sn-rng-dragon-tiger",
    img: game30,
    name: "sn rng dragon tiger",
  },
  {
    id: 23,
    href: "/casino/sn-rng-dragon-tiger-2020",
    img: game31,
    name: "sn rng dragon tiger 2020",
  },
  {
    id: 24,
    href: "/casino/sn-rng-joker",
    img: game32,
    name: "sn rng joker",
  },
  {
    id: 25,
    href: "/casino/sn-rng-teen-patti",
    img: game36,
    name: "sn rng teen patti",
  },
  {
    id: 26,
    href: "/casino/sn-rng-teen-patti-2020",
    img: game37,
    name: "sn rng teen patti 2020",
  },
  {
    id: 27,
    href: "/casino/sn-teen-patti-face-off",
    img: game38,
    name: "sn teen patti face off",
  },
  {
    id: 28,
    href: "/casino/vivo-andar-bahar",
    img: populargame1,
    name: "vivo andar bahar",
  },
  {
    id: 29,
    href: "/casino/vivo-teen-patti",
    img: populargame2,
    name: "vivo teen patti",
  },
  {
    id: 30,
    href: "/casino/vivo-oracle-blaze-roulette",
    img: populargame3,
    name: "vivo oracle blaze roulette",
  },
  {
    id: 31,
    href: "/casino/vivo-portomaso-roulette",
    img: populargame4,
    name: "vivo portomaso roulette",
  },
  {
    id: 32,
    href: "/casino/vivo-las-vegas-roulette",
    img: populargame5,
    name: "vivo las vegas roulette",
  },
  {
    id: 33,
    href: "/casino/vivo-macau-baccarat",
    img: populargame6,
    name: "vivo macau baccarat",
  },
  {
    id: 34,
    href: "/casino/vivo-las-vegas-blackjack",
    img: populargame8,
    name: "vivo las vegas blackjack",
  },
  {
    id: 35,
    href: "/casino/vivo-dragon-tiger",
    img: populargame9,
    name: "vivo dragon tiger",
  },
  {
    id: 36,
    href: "/casino/ezgrt-gonzos-quest-megaways",
    img: populargame10,
    name: "ezgrt gonzos quest megaways",
  },
  {
    id: 37,
    href: "/casino/ezg-auto-roulette",
    img: populargame11,
    name: "ezg auto roulette",
  },
  {
    id: 38,
    href: "/casino/ezg-bet-on-teen-patti",
    img: populargame12,
    name: "ezg bet on teen patti",
  },
  {
    id: 39,
    href: "/casino/ezg-andar-bahar",
    img: populargame13,
    name: "ezg andar bahar",
  },
  {
    id: 40,
    href: "/casino/ezg-baccarat",
    img: populargame14,
    name: "ezg baccarat",
  },
  {
    id: 41,
    href: "/casino/ezg-lucky-7",
    img: populargame15,
    name: "ezg lucky 7",
  },
  {
    id: 42,
    href: "/casino/ezg-dragon-tiger",
    img: populargame16,
    name: "ezg dragon tiger",
  },
  {
    id: 43,
    href: "/casino/ezg-casino-marina-andar-bahar",
    img: populargame17,
    name: "ezg casino marina andar bahar",
  },
  {
    id: 44,
    href: "/casino/ezg-italian-blackjack",
    img: populargame18,
    name: "ezg italian blackjack",
  },
  {
    id: 45,
    href: "/casino/ezg-diamond-roulette",
    img: populargame19,
    name: "ezg diamond roulette",
  },
  {
    id: 46,
    href: "/casino/spribe/aviator",
    img: aviator,
    name: "aviator",
  },
];
