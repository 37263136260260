import React, { useState } from "react";
import MoonLight from "../../assets/images/moon.svg";
import SunLight from "../../assets/images/sunny.svg";
import { useDispatch, useSelector } from "react-redux";
import { WEBSITE_THEME } from "../../config/const";
import { setAppTheme } from "../../redux/app/actions";

export const BUTTON_PARENT_TYPE = {
  HEADER: "HEADER",
  SIDEBAR: "SIDEBAR",
};

const ThemeToggle = ({ buttonParentType = BUTTON_PARENT_TYPE.HEADER }) => {
  const { appTheme } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const toggleClass = () => {
    if (appTheme === WEBSITE_THEME.LIGHT) {
      dispatch(setAppTheme(WEBSITE_THEME.DARK));
      document.body.classList.remove("light-theme");
    } else {
      dispatch(setAppTheme(WEBSITE_THEME.LIGHT));
      document.body.classList.add("light-theme");
    }
  };

  return (
    <div
      className={`theme-btn ${
        buttonParentType === BUTTON_PARENT_TYPE.HEADER && "me-2"
      }`}
    >
      <button type="button" className="btn" onClick={toggleClass}>
        <img src={SunLight} alt="sun" className="light_theme" />
        <img src={MoonLight} alt="moon" className="dark_theme" />
      </button>
    </div>
  );
};
export default ThemeToggle;
